import {
  Button,
  Grid,
  Typography,
  useTheme,
  Paper,
  Container,
} from "@mui/material";

export const EmptyData = (props) => {
  const theme = useTheme();
  const {
    empImage,
    emImage,
    empText,
    btnIcon,
    btnText,
    setAddBrand,
    hideButton,
    onClick,
  } = props;

  const handleAddBrandClick = (event) => {
    setAddBrand(true);
  };

  return (
    <Paper
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          direction={"column"}
          // xs="4"
          sx={{ alignItems: "center", backgroundColor: "red", p: 1 }}
        >
          <Grid item>
            <img src={empImage} style={{ width: 350, height: 280 }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.tertiary.main,
                fontSize: 18,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {empText}
            </Typography>
          </Grid>
          {!hideButton && (
            <Grid item sx={{ mt: 2, mb: 4 }}>
              <Button
                variant="contained"
                disableElevation
                color="theme"
                startIcon={btnIcon}
                style={{ textTransform: "none" }}
                onClick={handleAddBrandClick}
                sx={{
                  width: 180,
                  height: 45,
                }}
              >
                {btnText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </Paper>
  );
};
