import { imageRoundSmallPlaceholder } from "../assets";

export const sellDropdownMenu = [
  { id: 1, title: "Order Confirmed" },
  { id: 2, title: "Pick & Sort" },
  { id: 3, title: "Outbound Scan & Packing Completed" },
  { id: 4, title: "Payment Received" },
  { id: 5, title: "Picked Up" },
];

export const purchaseDropdownMenu = [
  { id: 1, title: "Package Received" },
  { id: 2, title: "Inbound Scan Completed" },
  { id: 3, title: "Seller Settlement Completed" },
];

const sideMenuItemsMain = [
  [
    { id: 1, title: "Dashboard", link: "/dashboard" },
    { id: 2, title: "Stocks", link: "/stocks" },
    { id: 3, title: "Live Orders", link: "/live_orders" },
    { id: 4, title: "Purchase", link: "/purchase" },
    { id: 5, title: "Sell", link: "/sell" },
    { id: 6, title: "Requests", link: "/requests" },
  ],
  [
    { id: 7, title: "Products", link: "/products" },
    { id: 8, title: "Users", link: "/users" },
    { id: 9, title: "Payment (Admin)", link: "/payment" },
    { id: 10, title: "Settings", link: "/settings" },
    // { id: 11, title: "Notifications", link: "/notifications" },
    { id: 12, title: "Logout", link: "/logout" },
  ],
];

const sideMenuItemsWarehouse = [
  [
    { id: 4, title: "Purchase", link: "/purchase" },
    { id: 5, title: "Sell", link: "/sell" },
  ],
  [{ id: 12, title: "Logout", link: "/logout" }],
];

const sideMenuItemsFinance = [
  [
    { id: 1, title: "Dashboard", link: "/dashboard" },
    { id: 4, title: "Purchase", link: "/purchase" },
    { id: 5, title: "Sell", link: "/sell" },
  ],
  [
    { id: 8, title: "Users", link: "/users" },
    { id: 9, title: "Payment (Finance)", link: "/payment" },
    { id: 12, title: "Logout", link: "/logout" },
  ],
];

const sideMenuWithoutRole = [
  [{ id: 12, title: "Logout", link: "/logout" }],
  [],
];

const sampleSelectItems = [
  { id: 1, name: "Option One", quantity: "20/20" },
  { id: 2, name: "Option Two", quantity: "21/20" },
  { id: 3, name: "Option Three" },
  { id: 4, name: "Option Four" },
  { id: 5, name: "Option Five" },
];
const sampleFilterItems = [
  { id: 1, name: "Filter By" },
  { id: 2, name: "Filter By" },
  { id: 3, name: "Filter By" },
  { id: 4, name: "Filter By" },
  { id: 5, name: "Filter By" },
];

const sampleTreeItems = [
  {
    id: 1,
    name: "Apple",
    icon: imageRoundSmallPlaceholder,
    badge: 24,
    selected: 10,
    children: [
      {
        id: 2,
        name: "iPhone",
        badge: 14,
        selected: 0,
        children: [
          { id: 3, name: "iphone 13", badge: 2, selected: 0, children: [] },
          {
            id: 4,
            name: "iphone 13 Mini",
            badge: 2,
            children: [],
          },
          {
            id: 5,
            name: "iphone 13 Max",
            badge: 3,
            children: [],
          },
          { id: 6, name: "iphone 14", badge: 3, selected: 0, children: [] },
          {
            id: 7,
            name: "iphone 14 Max",
            badge: 2,
            children: [],
          },
          { id: 8, name: "iphone 15", badge: 1, selected: 0, children: [] },
          {
            id: 9,
            name: "iphone 15 Max",
            badge: 1,
            selected: 0,
            children: [],
          },
        ],
      },
      {
        id: 10,
        name: "iPad",
        badge: 10,
        selected: 12,
        children: [
          {
            id: 11,
            name: "ipad 2021",
            badge: 3,
            selected: 0,
            children: [],
          },
          {
            id: 12,
            name: "ipad 2022",
            badge: 5,
            selected: 0,
            children: [],
          },
          {
            id: 13,
            name: "ipad Pro 2022",
            badge: 2,
            selected: 0,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 14,
    name: "OnePlus",
    icon: imageRoundSmallPlaceholder,
    badge: 40,
    selected: 0,
    children: [
      { id: 15, name: "OnePlus 7", badge: 10, selected: 0, children: [] },
      { id: 16, name: "OnePlus 8", badge: 5, selected: 0, children: [] },
      { id: 17, name: "OnePlus 9", badge: 8, selected: 0, children: [] },
      { id: 18, name: "OnePlus 10", badge: 2, selected: 0, children: [] },
      {
        id: 19,
        name: "OnePlus Nord I",
        badge: 5,
        selected: 0,
        children: [],
      },
      {
        id: 20,
        name: "OnePlus Nord II",
        badge: 5,
        selected: 0,
        children: [],
      },
      {
        id: 21,
        name: "OnePlus Nord III",
        badge: 5,
        selected: 0,
        children: [],
      },
    ],
  },
  {
    id: 22,
    name: "Asus",
    icon: imageRoundSmallPlaceholder,
    badge: 44,
    selected: 0,
    children: [
      {
        id: 23,
        name: "Laptop",
        badge: 20,
        selected: 0,
        children: [
          {
            id: 24,
            name: "Asus Ryzen 7",
            badge: 4,
            selected: 0,
            children: [],
          },
          {
            id: 25,
            name: "Asus Ryzen 8",
            badge: 3,
            selected: 0,
            children: [],
          },
          {
            id: 26,
            name: "Asus Ryzen 9",
            badge: 3,
            selected: 0,
            children: [],
          },
          {
            id: 27,
            name: "Asus Ryzen 10",
            badge: 10,
            selected: 0,
            children: [],
          },
        ],
      },
      {
        id: 28,
        name: "Phone",
        badge: 8,
        selected: 0,
        children: [
          {
            id: 29,
            name: "Asus Aspire 1",
            badge: 2,
            selected: 0,
            children: [],
          },
          {
            id: 30,
            name: "Asus Aspire 2",
            badge: 4,
            selected: 0,
            children: [],
          },
          {
            id: 31,
            name: "Asus Aspire 3",
            badge: 2,
            selected: 0,
            children: [],
          },
        ],
      },
      {
        id: 32,
        name: "Tablet",
        badge: 16,
        selected: 0,
        children: [
          {
            id: 33,
            name: "Asus Tab 1",
            badge: 6,
            selected: 0,
            children: [],
          },
          {
            id: 34,
            name: "Asus Tab 1",
            badge: 6,
            selected: 0,
            children: [],
          },
          {
            id: 35,
            name: "Asus Tab 1",
            badge: 4,
            selected: 0,
            children: [],
          },
        ],
      },
    ],
  },
];
const passwordValidations = [
  { type: 1, title: "Minimum 8 characters in length", isValid: null },
  { type: 2, title: "Atleast 1 capital letter", isValid: null },
  { type: 3, title: "Atleast 1 small letter", isValid: null },
  { type: 4, title: "Atleast 1 number", isValid: null },
  { type: 5, title: "Atleast 1 special character", isValid: null },
];
const sampleStockItems = [
  {
    id: 1,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194345673567, Model ID: MNW57SL/A",
    quantity: 60,
    bestPrice: [
      { priceGap: 1, price: "$390.00", total: 1 },
      { priceGap: 5, price: "$350.00", total: 4 },
      { priceGap: 20, price: "$340.00", total: 15 },
    ],
  },
  {
    id: 2,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194368743567, Model ID: MNV69GH/A",
    quantity: 0,
    bestPrice: [],
  },
  {
    id: 3,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194342258567, Model ID: MGN52RL/B",
    quantity: 18,
    bestPrice: [
      { priceGap: 1, price: "$390.00", total: 20 },
      { priceGap: 5, price: "$320.00", total: 18 },
    ],
  },
  {
    id: 4,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194342690567, Model ID: MJW26RL/A",
    quantity: 100,
    bestPrice: [
      { priceGap: 1, price: "$390.00", total: 46 },
      { priceGap: 5, price: "$310.00", total: 22 },
      { priceGap: 20, price: "$340.00", total: 7 },
    ],
  },
];

const sampleLiveOrderItems = [
  {
    id: 1,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194345673567, Model ID: MNW57SL/A",
    seller: "C Company",
    buyer: "Divine Mobiles",
    quantity: 109,
    price: "$ 390.00",
    total: "$ 2390.00",
    orderquantity: "20/20",
  },
  {
    id: 2,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194345673567, Model ID: MNW57SL/A",
    seller: "D Trading",
    buyer: "Kane Solutions",
    quantity: 132,
    price: "$ 4618.00",
    total: "$ 2390.00",
    orderquantity: "03/50",
  },
  {
    id: 3,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194345673567, Model ID: MNW57SL/A",
    seller: "ABC Tech",
    buyer: "Saniya Computers",
    quantity: 78,
    price: "$ 3100.00",
    total: "$ 2390.00",
    orderquantity: "0/20",
  },
  {
    id: 4,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    modelName: "UPC: 194345673567, Model ID: MNW57SL/A",
    seller: "Geo Info",
    buyer: "Nova Mobiles",
    quantity: 220,
    price: "$ 625.00",
    total: "$ 7615.00",
    orderquantity: "0/100",
  },
];
const samplePurchaseOrderItems = [
  {
    id: 1,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    orderquantity: "20/20",
  },
  {
    id: 2,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    orderquantity: "03/50",
  },
  {
    id: 3,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    orderquantity: "0/20",
  },
  {
    id: 4,
    productName: "iPhone 15 Plus 256GB Black- UAE",
    orderquantity: "0/100",
  },
];
const samplePurchaseItems = [
  {
    id: 1,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s9876",
    seller: "DAC trading",
    quantity: "0 / 300",
    total: "$ 1122500.00",
    status: "Pending",
  },
  {
    id: 2,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s9876",
    seller: "DAC trading",
    quantity: "0 / 300",
    total: "$ 1122500.00",
    status: "Completed",
  },
  {
    id: 3,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s9876",
    seller: "DAC trading",
    quantity: "0 / 300",
    total: "$ 1122500.00",
    status: "Completed",
  },
  {
    id: 4,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s9876",
    seller: "DAC trading",
    quantity: "0 / 300",
    total: "$ 1122500.00",
    status: "Completed",
  },
];
const samplePurchaseSerial = [
  {
    id: 1,
    date: "15 Oct 2023,  03:00 PM",
    orderid: "s9876",
    seller: "DAC trading",
    sellerid: "s9876",
    buyer: "A G Trading",
    buyerid: "B7343",
  },
  {
    id: 2,
    date: "15 Oct 2023,  03:00 PM",
    orderid: "s9876",
    seller: "DAC trading",
    sellerid: "s9876",
    buyer: "A G Trading",
    buyerid: "B7343",
  },
  {
    id: 3,
    date: "15 Oct 2023,  03:00 PM",
    orderid: "s9876",
    seller: "DAC trading",
    sellerid: "s9876",
    buyer: "A G Trading",
    buyerid: "B7343",
  },
];
const sampleRequestsItems = [
  {
    id: 1,
    company: "A G Trading",
    requestId: "45456",
    date: "12 May 2023, 3:08 PM",
    type: "Buyer",
    status: "Pending",
  },
  {
    id: 2,
    company: "A G Trading",
    requestId: "45456",
    date: "12 May 2023, 3:08 PM",
    type: "Buyer",
    status: "Pending",
  },
  {
    id: 3,
    company: "A G Trading",
    requestId: "45456",
    date: "12 May 2023, 3:08 PM",
    type: "Seller",
    status: "Approved",
  },
  {
    id: 4,
    company: "A G Trading",
    requestId: "45456",
    date: "12 May 2023, 3:08 PM",
    type: "Buyer",
    status: "Rejected",
  },
];
const sampleSellItems = [
  {
    id: 1,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s7343",
    seller: "COM Mobiles",
    quantity: "273/300",
    total: "$ 1622500.00",
    status: "Pending",
  },
  {
    id: 2,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s7343",
    seller: "COM Mobiles",
    quantity: "273/300",
    total: "$ 1622500.00",
    status: "Completed",
  },
  {
    id: 3,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s7343",
    seller: "COM Mobiles",
    quantity: "273/300",
    total: "$ 1622500.00",
    status: "Completed",
  },
  {
    id: 4,
    date: "15 Oct 2023, 03:00 PM",
    orderId: "s7343",
    seller: "COM Mobiles",
    quantity: "273/300",
    total: "$ 1622500.00",
    status: "Completed",
  },
];
const sampleBuyersItems = [
  {
    id: 1,
    company: "5G Mobiles",
    buyerid: "B41699",
    category: "Cat - A",
    totalorders: "297",
    totalsales: "$ 1122500.00",
    balance: "$ 11000.00",
  },
  {
    id: 2,
    company: "5G Mobiles",
    buyerid: "B41699",
    category: "Cat - A",
    totalorders: "297",
    totalsales: "$ 1122500.00",
    balance: "$ 11000.00",
  },
  {
    id: 3,
    company: "5G Mobiles",
    buyerid: "B41699",
    category: "Cat - A",
    totalorders: "297",
    totalsales: "$ 1122500.00",
    balance: "$ 11000.00",
  },
  {
    id: 4,
    company: "5G Mobiles",
    buyerid: "B41699",
    category: "Cat - A",
    totalorders: "297",
    totalsales: "$ 1122500.00",
    balance: "$ 11000.00",
  },
];
const sampleSellersItems = [
  {
    id: 1,
    company: "AKG Technologies",
    sellerid: "S71900",
    totalorders: "189",
    totalsales: "$ 1122500.00",
    balance: "$ 23000.00",
  },
  {
    id: 2,
    company: "AKG Technologies",
    sellerid: "S71900",
    totalorders: "189",
    totalsales: "$ 1122500.00",
    balance: "$ 23000.00",
  },
  {
    id: 3,
    company: "AKG Technologies",
    sellerid: "S71900",
    totalorders: "189",
    totalsales: "$ 1122500.00",
    balance: "$ 23000.00",
  },
  {
    id: 4,
    company: "AKG Technologies",
    sellerid: "S71900",
    totalorders: "189",
    totalsales: "$ 1122500.00",
    balance: "$ 23000.00",
  },
];

const samplePaymentItems = [
  {
    id: 1,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 2,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 3,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 4,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 5,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 6,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
  {
    id: 7,
    date: "15 Oct 2023,  03:00 PM",
    transactionId: "1312",
    descriptionHeader: "Payment to seller order s61728",
    description: "All pending amount has been submitted.",
    amount: "$ 10000.00",
  },
];

const sampleUserSettingsItems = [
  {
    userNo: 1,
    name: "John Wick",
    username: "johnwick",
    password: "wick@123",
    role: "Admin Full Access",
  },
  {
    userNo: 2,
    name: "Johnny William",
    username: "johnnywilliam",
    password: "william@123",
    role: "Admin Full Access",
  },
  {
    userNo: 3,
    name: "John Rafi",
    username: "johnrafi",
    password: "rafi@123",
    role: "Admin Full Access",
  },
  {
    userNo: 4,
    name: "John Roy",
    username: "johnroy",
    password: "roy@123",
    role: "Admin Full Access",
  },
  {
    userNo: 5,
    name: "Normal Will",
    username: "normanwill",
    password: "will@123",
    role: "Admin Full Access",
  },
];

const sampleNotificationItems = [
  {
    id: 1,
    read: false,
    notification:
      "DAC Trading ( seller) has modified their trade license document",
    date: "24 Min ago",
  },
  {
    id: 2,
    read: false,
    notification: "C Mobile ( Buyer) Passport is expiring on 31/12/2023",
    date: "42 min ago",
  },
  {
    id: 3,
    read: true,
    notification: "DM Mobiles ( Buyer) Passport is expiring on 31/12/2023",
    date: "16th December, 2023",
  },
  {
    id: 4,
    read: true,
    notification:
      "jKC Trading ( seller) has modified their trade license document",
    date: "19th December, 2023",
  },
  {
    id: 5,
    read: true,
    notification:
      "home Tech ( seller) has modified their trade license document",
    date: "23th December, 2023",
  },
];

const samplePurchaseSummaryItems = [
  {
    id: 1,
    product: "iphone 15 plus 128GB black - UAE",
    orderId: "B8678",
    buyerId: "64434",
    quantity: "20/20",
    price: "$ 390.00",
    total: "$ 7800.00",
  },
  {
    id: 2,
    product: "iphone 15 plus 128GB black - UAE",
    orderId: "B8678",
    buyerId: "64434",
    quantity: "20/20",
    price: "$ 390.00",
    total: "$ 7800.00",
  },
  {
    id: 3,
    product: "iphone 15 plus 128GB black - UAE",
    orderId: "B8678",
    buyerId: "64434",
    quantity: "20/20",
    price: "$ 390.00",
    total: "$ 7800.00",
  },
  {
    id: 4,
    product: "iphone 15 plus 128GB black - UAE",
    orderId: "B8678",
    buyerId: "64434",
    quantity: "20/20",
    price: "$ 390.00",
    total: "$ 7800.00",
  },
];
const sampleProductAttributes = [
  {
    id: 1,
    attribute: "Country",
  },
  {
    id: 2,
    attribute: "Colour",
  },
  {
    id: 3,
    attribute: "Size",
  },
];
const sampleProductItems = [
  {
    id: 1,
    item: "name",
  },
  {
    id: 2,
    item: "item",
  },
  {
    id: 3,
    item: "item",
  },
];

const documentItems = [
  {
    id: 1,
    title: "Business Certificate",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
  },
  {
    id: 2,
    title: "TRN Certificate",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
  },
  {
    id: 3,
    title: "Owners Passport",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
  },
  {
    id: 4,
    title: "Emirates ID",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
  },
];

const documentStatuses = [
  {
    id: 1,
    title: "Business Certificate",
    isexpired: false,
    isrejected: false,
    time: "13 may 2023,  12:10 PM",
    iscompleted: true,
  },
  {
    id: 2,
    title: "TRN Certificate",
    isexpired: false,
    isrejected: false,
    time: "13 may 2023,  12:10 PM",
    iscompleted: true,
  },
  {
    id: 3,
    title: "Owners Passport",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
    isrejected: false,
    time: "13 may 2023,  12:10 PM",
    iscompleted: false,
  },
  {
    id: 4,
    title: "Emirates ID",
    expiry: "Expires on 01/01/2024",
    isexpired: false,
    isrejected: false,
    time: "13 may 2023,  12:10 PM",
    iscompleted: false,
  },
];

const userRoles = {
  superAdmin: "admin_super",
  master: "admin_master",
  warehouse: "admin_warehouse",
  finance: "admin_finance",
};

export {
  sideMenuItemsMain,
  sideMenuItemsWarehouse,
  sideMenuItemsFinance,
  sideMenuWithoutRole,
  sampleSelectItems,
  sampleFilterItems,
  sampleTreeItems,
  sampleStockItems,
  sampleLiveOrderItems,
  samplePurchaseItems,
  sampleSellItems,
  sampleBuyersItems,
  sampleSellersItems,
  samplePurchaseOrderItems,
  samplePaymentItems,
  sampleUserSettingsItems,
  sampleNotificationItems,
  sampleRequestsItems,
  samplePurchaseSummaryItems,
  documentItems,
  documentStatuses,
  samplePurchaseSerial,
  sampleProductAttributes,
  sampleProductItems,
  userRoles,
};
