import { Fragment, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import PropTypes from "prop-types";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import { useUsersStore } from "../../store/store";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { usePopup, useModal } from "../../hooks";
import { EditDeletePopover } from "../popup/EditDeletePopover";
import { EditAdminDialog } from "../dialogs/EditAdminDialog";
import { ReminderDialog } from "../dialogs/ReminderDialog";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    borderRadius: "10px",
    borderCollapse: "unset !important",

    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export const AdminsTable = (props) => {
  const {
    onCountChange,
    searchInput,
    isNewAdminCreated,
    onNewAdminHandled,
    showButton,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    admins,
    setAdmins,
    totalCount,
    setTotalCount,
    currency,
    setCurrency,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
  } = useUsersStore((state) => state);
  const [selectedUser, setSelectedUser] = useState(null);
  const [menuPopup, openMenuPopup, closeMenuPopup] = usePopup();
  const [isEditUserModal, openEditUserModal, closeEditUserModal] = useModal();
  const [isDeleteUserModal, openDeleteUserModal, closeDeleteUserModal] =
    useModal();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShouldReload(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShouldReload(true);
  };

  const showEditDeleteMenu = (e, user) => {
    setSelectedUser(user);
    openMenuPopup(e.currentTarget);
  };

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.black.main,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.tablecolor.main,
  };

  const getAdmins = () => {
    let params = {
      page: page + 1,
      limit: rowsPerPage,
      search_key: searchInput,
    };
    api
      .get(urls.adminsList, { params: params })
      .then((res) => {
        setAdmins(res.data.data?.admins ?? []);
        setTotalCount(res.data.data?.pagination?.total_admins ?? 0);
        onCountChange({
          buyers: res.data.data?.pagination?.total_buyers ?? 0,
          sellers: res.data.data?.pagination?.total_sellers ?? 0,
          admins: res.data.data?.pagination?.total_admins ?? 0,
        });
        setCurrency(res.data.data?.currency ?? "");
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  const deleteUser = () => {
    closeDeleteUserModal(false);
    api
      .delete(`${urls.deleteAdmin}${selectedUser.id}`)
      .then((res) => {
        showToast(res.data.message, "success");
        getAdmins();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  const onEditUser = () => {
    closeEditUserModal();
    getAdmins();
  };

  useEffect(() => {
    if (isFirstLoad) {
      getAdmins();
    }
  }, []);

  useEffect(() => {
    if (isNewAdminCreated) {
      getAdmins();
      onNewAdminHandled(); // Notify UsersTable that the new admin creation has been handled
    }
  }, [isNewAdminCreated, onNewAdminHandled]);

  useEffect(() => {
    if (shouldReload) {
      getAdmins();
    }
  }, [shouldReload]);

  return (
    <Fragment>
      <EditDeletePopover
        open={Boolean(menuPopup)}
        anchorEl={menuPopup}
        onClose={closeMenuPopup}
        onEdit={openEditUserModal}
        onDelete={openDeleteUserModal}
      />
      {isEditUserModal && (
        <EditAdminDialog
          open={isEditUserModal}
          user={selectedUser}
          onClose={closeEditUserModal}
          onSubmit={onEditUser}
        />
      )}
      {isDeleteUserModal && (
        <ReminderDialog
          open={isDeleteUserModal}
          onClose={() => {
            closeDeleteUserModal(false);
          }}
          onConfirm={deleteUser}
          header={"Delete User"}
          reminder={
            "Are you sure you want to delete this user? This action cannot be undone."
          }
          confirm={"Delete"}
        />
      )}
      {isLoading && admins.length === 0 && <LoaderCard />}
      {!isLoading && admins.length === 0 && <EmptyCard />}
      {admins.length > 0 && (
        <TableContainer component={Box}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={headerStyle}>No.</TableCell>
                <TableCell sx={headerStyle}>Name</TableCell>
                <TableCell sx={headerStyle}>Email</TableCell>
                <TableCell sx={headerStyle}>Whatsapp</TableCell>
                <TableCell sx={headerStyle}>Role</TableCell>
                {showButton && <TableCell className="tableHead"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((item, i) => (
                <TableRow key={i}>
                  <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                  <TableCell sx={itemStyle}>{item.name}</TableCell>
                  <TableCell sx={itemStyle}>{item.email}</TableCell>
                  <TableCell sx={itemStyle} component="th" scope="row">
                    {item.phone_code} {item.phone_number}
                  </TableCell>
                  <TableCell sx={itemStyle}>{item.role_name}</TableCell>
                  {showButton && (
                    <TableCell className="tableRow">
                      <IconButton
                        aria-label="edit"
                        size="medium"
                        onClick={(e) => showEditDeleteMenu(e, item)}
                      >
                        <MoreHorizIcon color="black" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {admins.length > 0 && (
        <TablePagination
          component="div"
          align="right"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: 1, mb: 1 }}
        />
      )}
    </Fragment>
  );
};

AdminsTable.propTypes = {
  onCountChange: PropTypes.func,
  searchInput: PropTypes.string,
};
