import { Routes, Route, Navigate } from "react-router-dom";
import { LiveOrdersScreen } from "../pages/dashboard/LiveOrdersScreen";
import { PurchaseScreen } from "../pages/dashboard/PurchaseScreen";
import { SideMenu } from "../components/menu/SideMenu";
import { Fragment, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DashboardScreen } from "../pages/dashboard/DashboardScreen";
import { StockScreen } from "../pages/dashboard/StockScreen";
import { SellScreen } from "../pages/dashboard/SellScreen";
import { UsersScreen } from "../pages/dashboard/UsersScreen";
import { BuyersOrders } from "../pages/orders/BuyersOrder";
import { SellersOrders } from "../pages/orders/SellersOrder";
import { NotScreen } from "../pages/dashboard/NotScreen";
import { ProductScreen } from "../pages/dashboard/ProductsScreen";
import PurchaseScanningItems from "../components/items/PurchaseScanningItems";
import { SettingsScreen } from "../pages/dashboard/SettingsScreen";
import { PaymentScreen } from "../pages/dashboard/PaymentScreen";
import { RequestDetailScreen } from "../pages/dashboard/RequestDetailScreen";
import PurchaseScannedItems from "../components/items/PurchaseScannedItems";
import { RequestsScreen } from "../pages/dashboard/RequestsScreen";
import { PurchaseSummary } from "../pages/summary/PurchaseSummary";
import { SellSummary } from "../pages/summary/SellSummary";
import PurchaseScanInBound from "../components/alert/PurchaseScanInBound";
import { useAuth } from "../context";
import { MainRoute } from "./Redirection";
import { userRoles } from "../utilities";

export function MainRoutes() {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn, userRole } = useAuth();

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <Fragment>
      <Stack direction={"row"}>
        {isLoggedIn && (
          <SideMenu
            isOpen={isOpen}
            openMenu={handleOpenMenu}
            closeMenu={handleCloseMenu}
          />
        )}
        <Box
          sx={{
            width: "100%",
            // height: "100vh",
            pt: 2,
            pb: 2,
            ml: 15,
          }}
        >
          <Routes>
            <Route element={<MainRoute />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardScreen />} />
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/stocks" element={<StockScreen />} />
              )}
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/live_orders" element={<LiveOrdersScreen />} />
              )}
              <Route path="/purchase" element={<PurchaseScreen />} />
              <Route path="/sell" element={<SellScreen />} />
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/requests" element={<RequestsScreen />} />
              )}
              {/* <Route path="/sellers" element={<SellersScreen />} />
            <Route path="/buyers" element={<BuyersScreen />} /> */}
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/products" element={<ProductScreen />} />
              )}
              {userRole !== userRoles.warehouse && (
                <Route path="/users" element={<UsersScreen />} />
              )}
              <Route path="/payment" element={<PaymentScreen />} />
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/settings" element={<SettingsScreen />} />
              )}
              {(userRole === userRoles.superAdmin ||
                userRole === userRoles.master) && (
                <Route path="/notifications" element={<NotScreen />} />
              )}
              <Route
                path="/users/buyer_detail/:id"
                element={<BuyersOrders />}
              />
              <Route
                path="/users/seller_detail/:id"
                element={<SellersOrders />}
              />
              {/* <Route path="purchase/purchase_summary/:id" element={<PurchaseSummary />} /> */}
              <Route
                path="/purchase_summary/:id"
                element={<PurchaseSummary />}
              />
              <Route path="/sell_summary/:id" element={<SellSummary />} />
              {/* <Route path="/productattribute/:id" element={<EditAttributes />} /> */}

              {/* <Route path="/logout" element={<LoginScreen/>} /> */}
              <Route
                path="/requests_detail/:uid"
                element={<RequestDetailScreen />}
              />
              <Route
                path="/purchasescanning"
                element={<PurchaseScanningItems />}
              />
              <Route
                path="/purchase_scan_inbound"
                element={<PurchaseScanInBound />}
              />
              <Route
                path="/purchasescanned"
                element={<PurchaseScannedItems />}
              />
            </Route>
          </Routes>
        </Box>
      </Stack>
    </Fragment>
  );
}
