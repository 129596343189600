import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { sampleTreeItems } from "../../utilities";
import { Add } from "iconsax-react";
import { AddMainCategory } from "../alert/AddMainCategory";
import { CategoryCard } from "../cards/CategoryCard";
import PropTypes from "prop-types";
import CategoryEditDeleteMenu from "./CategoryEditDeleteMenu";
import { useModal } from "../../hooks";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

const MemoizedCategoryCard = React.memo(CategoryCard);
const MemoizedEditDeleteMenu = React.memo(CategoryEditDeleteMenu);

export const CategoryMenu = (props) => {
  const {
    data,
    onCategoryClick,
    selectedID,
    brandID,
    onCategoriesChange,
    showButton,
  } = props;
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [products, setProducts] = useState([...sampleTreeItems]);
  const [openMainDialog, setOpenMainDialog] = useState(false);
  const [selCategory, setSelCategory] = useState(null);
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModal();
  const [isOpenReactivateDialog, openReactivateDialog, closeReactivateDialog] =
    useModal();

  const boxStyle = {
    bgcolor: theme.palette.menuHeader.main,
    borderRadius: 2,
    width: 300,
    height: "88vh",
  };

  const handleOpenMain = useCallback(() => {
    setOpenMainDialog(true);
  }, []);

  const handleCloseMain = useCallback(() => {
    setOpenMainDialog(false);
    setSelCategory(null);
  }, []);

  const handleEditClick = useCallback(
    (item) => {
      setSelCategory(item);
      handleOpenMain();
    },
    [handleOpenMain]
  );

  const handleDeleteCategory = useCallback(() => {
    api
      .delete(urls.removeCategory + selCategory.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onCategoriesChange();
        closeDeleteDialog();
        setSelCategory(null);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  }, [
    api,
    urls.removeCategory,
    selCategory,
    showToast,
    onCategoriesChange,
    closeDeleteDialog,
    logout,
  ]);

  const handleUnhideCategory = useCallback(() => {
    api
      .put(urls.unhideCategory + selCategory.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onCategoriesChange();
        closeReactivateDialog();
        setSelCategory(null);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  }, [
    api,
    urls.removeCategory,
    selCategory,
    showToast,
    onCategoriesChange,
    closeDeleteDialog,
    logout,
  ]);

  const hasChildItem = (item) => {
    return item.sub_categories?.length > 0 || item.has_products;
  };

  const renderedItems = useMemo(() => {
    return data.map((item, i) => (
      <ListItem
        disablePadding
        secondaryAction={
          showButton ? (
            <MemoizedEditDeleteMenu
              onEditClick={() => handleEditClick(item)}
              onDeleteClick={(isDeactivated) => {
                setSelCategory(item);

                if (isDeactivated === true) {
                  //Already deactivated, so reactivate
                  openReactivateDialog();
                } else {
                  openDeleteDialog();
                }
              }}
              hasChildItem={hasChildItem(item)}
              isHiddenItem={!item.status}
            />
          ) : null
        }
        key={item.id}
      >
        <ListItemButton
          sx={{ p: 0 }}
          onClick={() => onCategoryClick(item)}
          selected={item.id === selectedID}
        >
          <Stack direction={"column"} sx={{ width: "100%" }}>
            <MemoizedCategoryCard node={item} />
            <Divider sx={{ mr: "-50px" }} />
          </Stack>
        </ListItemButton>
      </ListItem>
    ));
  }, [data, handleEditClick, onCategoryClick, selectedID, openDeleteDialog]);

  return (
    <Stack direction={"column"} sx={boxStyle}>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 16,
          fontWeight: 600,
          p: "12px",
        }}
      >
        Categories
      </Typography>
      <Divider />
      <List
        sx={{
          height: "100%",
          mt: "-4px",
          flexWrap: "inherit",
          overflowX: "scroll",
        }}
      >
        {renderedItems}
      </List>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        {showButton && (
          <Button
            variant="contained"
            disableElevation
            color="theme"
            onClick={handleOpenMain}
            startIcon={<Add />}
            style={{ textTransform: "none" }}
            sx={{ width: "100%", height: 45 }}
          >
            Add Category
          </Button>
        )}
        {openMainDialog && (
          <AddMainCategory
            openMainDialog={openMainDialog}
            onClose={handleCloseMain}
            brandID={brandID}
            onSubmit={onCategoriesChange}
            isEdit={Boolean(selCategory)}
            data={selCategory}
          />
        )}
        {isOpenDeleteDialog && (
          <ReminderDialog
            open={isOpenDeleteDialog}
            onClose={closeDeleteDialog}
            onConfirm={handleDeleteCategory}
            header={hasChildItem(selCategory) ? "Hide" : "Remove"}
            reminder={
              hasChildItem(selCategory)
                ? "Are you sure to hide this category?"
                : "Are you sure to remove this category?"
            }
            confirm={hasChildItem(selCategory) ? "Hide" : "Remove"}
          />
        )}
        {isOpenReactivateDialog && (
          <ReminderDialog
            open={isOpenReactivateDialog}
            onClose={closeReactivateDialog}
            onConfirm={handleUnhideCategory}
            header={"Unhide"}
            reminder={"Are you sure to unhide this category?"}
            confirm={"Unhide"}
          />
        )}
      </Box>
    </Stack>
  );
};

CategoryMenu.propTypes = {
  data: PropTypes.array.isRequired,
  onCategoryClick: PropTypes.func.isRequired,
  selectedID: PropTypes.string,
  brandID: PropTypes.string.isRequired,
  onCategoriesChange: PropTypes.func.isRequired,
};
