const urls = {
  //base url
  baseURL: {
    prod: "https://api.tera.trading/api/v1", // production
    test: "https://apitest.tera.trading/api/v1", // testing
  },

  //auth apis
  sendOtp: "/auth/send-otp",
  verifyOtp: "/auth/verify-otp",

  //settings
  createAttribute: "/admin/createAttribute",
  updateAttribute: "/admin/update-attributes",
  createAttributeItem: "/admin/add-attribute-description/",
  attributes: "/admin/attributes",
  attributeItems: "/admin/get-attribute-description",
  settingsList: "/admin/settings-list",
  sendNotification: "/admin/send-notification",
  updateAttributeItem: "/admin/update-attribute-description/",
  editSettings: "/admin/edit-settings",

  //purchase
  purchaseTableList: "/admin/seller-orders",
  purchaseOrderDetails: "/admin/seller-order-details",
  purchaseAddChargeDiscount: "/admin/seller/add-charge/",
  purchaseRemoveChargeDiscount: "/admin/seller/remove-charge/",
  purchaseSerialNumbers: "/admin/serial-numbers/seller/",
  updateOrderQuantity: "/admin/update-order-quantity",
  serialNumberSearch: "/admin/serial-number-search",
  purchaseDetailsDownload: "/admin/seller-order-details-downloads/",

  //sell
  sellTableList: "/admin/buyer-orders",
  sellOrderDetails: "/admin/buyer-order-details",
  sellAddChargeDiscount: "/admin/buyer/add-charge/",
  sellRemoveChargeDiscount: "/admin/buyer/remove-charge/",
  sellSerialNumbers: "/admin/serial-numbers/buyer/",
  sellDetailsDownload: "/admin/buyer-order-details-download/",

  //products
  brandsList: "/admin/brands",
  stockBrandsList: "/admin/products/brands",
  stockSellersList: "/admin/stock/sellers",
  stocksList: "/admin/stocks",
  categoriesList: "/admin/categories/",
  addBrand: "/admin/add-brand",
  updateBrand: "/admin/update-brand/",
  addSubCategory: "/admin/add-sub-category",
  productsList: "/admin/view-products/",
  tradeCategoriesList: "/admin/get-trade-category",
  addMainCategory: "/admin/add-main-category",
  categoryAttributes: "/admin/category-attributes/",
  addProduct: "/admin/add-product",
  editProduct: "/admin/edit-product",
  registrationRequests: "/admin/list-user-registrations",
  registrationRequestDetail: "/admin/request-details/",
  tradeCategories: "/admin/get-trade-category",
  addTradeCategory: "/admin/add-trade-category",
  attributeDescriptionList: "/admin/get-attribute-description",
  approveRejectCertfcate: "/admin/approve-certificate/",
  approveRejectUser: "/admin/approve-user/",
  editExpiryDate: "/admin/edit-expiry-date",
  mainCategoryDetails: "/admin/view-main-categories/",
  removeCategory: "/admin/remove-category/",
  editMainCategory: "/admin/update-main-categories/",
  editCommissionMatrices: "/admin/edit-main-category-commission-matrix/",
  removeAttribute: "/admin/delete-attributes/",
  removeAttributeDescription: "/admin/delete-attribute-description/",
  removeBrand: "/admin/delete-brand/",
  removeTradeCategory: "admin/delete-trade-category/",
  updateTradeCategory: "admin/update-trade-category/",
  updateSubCategory: "admin/update-sub-category/",
  unhideCategory: "/admin/reactivate-category/",
  hideProduct: "/admin/remove-product/",
  unHideProduct: "/admin/reactivate-product/",

  //users
  buyersList: "/admin/buyer-list",
  sellersList: "/admin/seller-list",
  userOrdersList: "/admin/user-orders/",
  adminsList: "/admin/user-list",
  adminRoles: "/admin/role-list",
  createAdmin: "/admin/create-user",
  editAdmin: "/admin/edit-user/",
  deleteAdmin: "/admin/user-delete/",

  //common
  fileUpload: "/common/file_upload",

  //scan
  checkUpc: "/admin/check-upc",
  inboundScan: "/admin/inbond-scan",
  outboundScan: "/admin/outbond-scan",
  purchaseScannedList: "/admin/scanned-product/seller",
  sellScannedList: "/admin/scanned-product/buyer",
  purchaseRemoveScannedProduct: "/admin/remove-scanned-product/sell",
  sellRemoveScannedProduct: "/admin/remove-scanned-product/buy",

  //status
  updateStatus: "/admin/update-order-status",
  updateSellerStatus: "/admin/update-seller-order-status",
  updateBuyerStatus: "/admin/update-buyer-order-status",

  //live orders
  liveOrders: "/admin/live-orders",
  downloadLiveOrders: "/admin/live-orders-download",

  //dashboard
  dashboard: "/admin/dashboard",
  dashboardChart: "/admin/dashboard-chart",

  //transactions
  transactionsList: "/common/transaction-list",
  addPayment: "/admin/add-payment",
};

export default urls;
