import { Popover, Stack, Typography, useTheme } from "@mui/material";
import { Edit2, Trash } from "iconsax-react";
import PropTypes from "prop-types";

export const EditDeletePopover = (props) => {
  const { open, onClose, anchorEl, onEdit, onDelete } = props;

  const theme = useTheme();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Stack direction="column" spacing={2} sx={{ pt: 2, pl: 3, pr: 3, pb: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          onClick={() => {
            onEdit();
            onClose();
          }}
          sx={{ cursor: "pointer" }}
        >
          <Edit2 size="20" color={theme.palette.primary.main} />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            Edit
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          onClick={() => {
            onDelete();
            onClose();
          }}
          sx={{ cursor: "pointer" }}
        >
          <Trash size="20" color={theme.palette.red.main} />
          <Typography
            sx={{
              color: theme.palette.red.main,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            Delete
          </Typography>
        </Stack>
      </Stack>
    </Popover>
  );
};

EditDeletePopover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
