import { useState } from "react";

export const usePopup = (initial = null) => {
  const [popupEl, setPopupEl] = useState(initial);

  const openPopup = (el) => {
    setPopupEl(el);
  };

  const closePopup = () => {
    setPopupEl(null);
  };

  return [popupEl, openPopup, closePopup];
};
