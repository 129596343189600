import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useCallback, useMemo } from "react";
import { BrandCard } from "../cards/BrandCard";
import { Add } from "iconsax-react";
import { AddBrands } from "../alert/AddBrands";
import PropTypes from "prop-types";
import EditRemoveMenu from "./EditRemoveMenu";
import React from "react";
import { useModal } from "../../hooks";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

const MemoizedEditRemoveMenu = React.memo(EditRemoveMenu);

export const BrandMenu = (props) => {
  const { data, onBrandClick, selectedID, onBrandsChange, showButton } = props;
  const theme = useTheme();
  const [openBrandDialog, setOpenBrandDialog] = useState(false);
  const [selBrand, setSelBrand] = useState(null);
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModal();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();

  const boxStyle = useMemo(
    () => ({
      bgcolor: theme.palette.menuHeader.main,
      borderRadius: 2,
      width: 300,
      height: "88vh",
    }),
    [theme.palette.menuHeader.main]
  );

  const handleOpenNewBrand = useCallback(() => {
    setOpenBrandDialog(true);
  }, []);

  const handleCloseNewBrand = useCallback(() => {
    setOpenBrandDialog(false);
    setSelBrand(null);
  }, []);

  const handleEditClick = useCallback(
    (item) => {
      setSelBrand(item);
      handleOpenNewBrand();
    },
    [handleOpenNewBrand]
  );

  const handleDeleteBrand = useCallback(() => {
    api
      .delete(urls.removeBrand + selBrand.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onBrandsChange();
        closeDeleteDialog();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  }, [
    api,
    urls.removeCategory,
    selBrand,
    showToast,
    onBrandsChange,
    closeDeleteDialog,
    logout,
  ]);

  const renderListItems = useMemo(
    () =>
      data.map((item, i) => (
        <ListItem
          disablePadding
          secondaryAction={
            showButton ? (
              <MemoizedEditRemoveMenu
                onEditClick={() => handleEditClick(item)}
                onRemoveClick={() => {
                  setSelBrand(item);
                  openDeleteDialog();
                }}
              />
            ) : null
          }
          key={i}
        >
          <ListItemButton
            sx={{ p: 0 }}
            onClick={() => onBrandClick(item.id)}
            selected={item.id === selectedID}
          >
            <Stack direction={"column"} sx={{ width: "100%" }}>
              <BrandCard node={item} />
              <Divider sx={{ mr: "-50px" }} />
            </Stack>
          </ListItemButton>
        </ListItem>
      )),
    [data, handleEditClick, onBrandClick, selectedID]
  );

  return (
    <Stack direction={"column"} sx={boxStyle}>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 16,
          fontWeight: 600,
          p: "12px",
        }}
      >
        Brands
      </Typography>
      <Divider />
      <List
        sx={{
          mt: "-4px",
          height: "100%",
          flexWrap: "inherit",
          overflowX: "auto",
        }}
      >
        {renderListItems}
      </List>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        {showButton && (
          <Button
            variant="contained"
            disableElevation
            color="theme"
            startIcon={<Add />}
            style={{ textTransform: "none" }}
            sx={{ width: "100%", height: 45 }}
            onClick={handleOpenNewBrand}
          >
            Add Brand
          </Button>
        )}
        {openBrandDialog && (
          <AddBrands
            open={openBrandDialog}
            onClose={handleCloseNewBrand}
            onSubmit={onBrandsChange}
            isEdit={Boolean(selBrand)}
            data={selBrand}
          />
        )}
        {isOpenDeleteDialog && (
          <ReminderDialog
            open={isOpenDeleteDialog}
            onClose={closeDeleteDialog}
            onConfirm={handleDeleteBrand}
            header={"Remove"}
            reminder={"Are you sure to remove this brand?"}
            confirm={"Remove"}
          />
        )}
      </Box>
    </Stack>
  );
};

BrandMenu.propTypes = {
  data: PropTypes.array.isRequired,
  onBrandClick: PropTypes.func.isRequired,
  selectedID: PropTypes.string,
  onBrandsChange: PropTypes.func.isRequired,
};
