import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Box,
  Stack,
  Divider,
  Backdrop,
  InputAdornment,
  Dialog,
  Button,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useAuth, useToast } from "../../context";
import { api, urls } from "../../service";

const useStyles = makeStyles((theme) => ({
  modelHeader: {
    padding: "20px 40px",
    borderBottom: "1px solid #DDDDDD",
    textAlign: "center",
  },
  modelBody: {
    padding: "20px 40px",
  },
  modelFooter: {
    padding: "20px 40px",
  },
  center: {
    justifyContent: "center",
  },
}));

export const EditAdminDialog = (props) => {
  const { open, user, onClose, onSubmit } = props;
  const theme = useTheme();

  const { logout } = useAuth();
  const { showToast } = useToast();

  const [apiCalling, setApiCalling] = useState(true);
  const [rolesList, setRolesList] = useState([]);
  const [validInputs, setValidInputs] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(true); // Tracks if any input has been modified
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    phone_code: `${user.phone_code}`,
    phone_number: user.phone_number,
    role_code_name: user.role_code_name,
    errors: {
      name: false,
      email: false,
      phone_number: false,
      role_code_name: false,
    },
  });

  const validateInput = (key, value) => {
    switch (key) {
      case "name":
        return value.trim() === ""; // Example: name should not be empty
      case "role_code_name":
        return value.trim() === ""; // Example: role code name should not be empty
      default:
        return false;
    }
  };

  const classes = useStyles();

  const getRolesList = () => {
    setApiCalling(true);
    api
      .get(urls.adminRoles)
      .then((res) => {
        let rolesList = res.data?.data;
        setRolesList(rolesList);
        setApiCalling(false);
      })
      .catch((err) => {
        setApiCalling(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const updateUser = () => {
    if (validInputs) {
      setApiCalling(true);
      const params = { ...formData };
      delete params.email;
      delete params.phone_code;
      delete params.phone_number;
      delete params.errors;

      api
        .put(`${urls.editAdmin}${user.id}`, params)
        .then((res) => {
          showToast(res.data.message, "success");
          setApiCalling(false);
          onSubmit();
        })
        .catch((err) => {
          setApiCalling(false);
          if (err.response.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      // Validate input and set error state
      curr.errors[key] = validateInput(key, value);
      return curr;
    });

    if (!hasInteracted) setHasInteracted(true);
  };

  useEffect(() => {
    if (open) {
      getRolesList();
    }
  }, [open]);

  useEffect(() => {
    if (hasInteracted) {
      // Check overall validity after the first interaction
      const hasErrors = Object.values(formData.errors).some((error) => error);
      setValidInputs(!hasErrors);
    }
  }, [formData, hasInteracted]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 2, pb: 4 }}>
        <Box className={classes.modelHeader}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 500,
              color: "#000",
              fontStyle: "normal",
            }}
          >
            Edit User
          </Typography>
        </Box>
        <Box className={classes.modelBody}>
          <Grid container spacing={3} direction="column">
            <Grid item md={12} sx={{ mt: 1 }}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-required"
                label="Name"
                value={formData.name}
                helperText={
                  formData.errors.name ? "Please enter the name" : null
                }
                onChange={(e) => updateFormData("name", e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-required"
                label="Email"
                value={formData.email}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                required
                fullWidth
                label="Whatsapp Number"
                type="number"
                value={formData.phone_number}
                error={formData.errors.phone_number}
                variant="outlined"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack direction={"row"}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#9E9E9E",
                            pr: "10px",
                          }}
                        >
                          {formData.phone_code}
                        </Typography>

                        <Divider orientation="vertical" flexItem />
                      </Stack>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  sx: {
                    fontSize: 16,
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                  },
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-select-currency"
                select
                label="User Type"
                value={formData.role_code_name}
                helperText={
                  formData.errors.role_code_name
                    ? "Please select the user role"
                    : null
                }
                onChange={(e) =>
                  updateFormData("role_code_name", e.target.value)
                }
              >
                {rolesList.map((role, index) => {
                  return (
                    <MenuItem key={index} value={role.role_code_name}>
                      {role.role_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Stack direction="row" spacing={2} sx={{ pl: 5, pr: 5 }}>
          <Button
            variant="contained"
            disableElevation
            color="lightTheme"
            onClick={onClose}
            sx={{
              width: "100%",
              height: 45,
              fontWeight: 500,
              fontSize: 16,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontWeight: 500,
              fontSize: 16,
              textTransform: "none",
            }}
            disabled={apiCalling || !validInputs}
            onClick={updateUser}
          >
            Update Admin
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
