import { useContext, useState, createContext, useEffect } from "react";
import { useLocalStorage } from "../hooks";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const { getItem, removeItem } = useLocalStorage();

  const token = getItem("access_token");
  const user = getItem("user_data");
  const encodedRole = getItem("userRole");

  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(token));
  const [userData, setUserData] = useState(JSON.parse(user));
  const [userRole, setUserRole] = useState(null);

  // Decode the role from Base64 if it exists
  const decodeRole = (encodedRole) => {
    return encodedRole ? atob(encodedRole) : null;
  };

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
    removeItem("access_token");
    removeItem("user_data");
    removeItem("userRole");
  };

  useEffect(() => {
    setUserData(JSON.parse(user));
  }, [user]);

  // Use useEffect to keep state in sync with localStorage
  useEffect(() => {
    setUserRole(decodeRole(encodedRole));
  }, [encodedRole]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, userRole, login, logout, userData }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
