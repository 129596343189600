import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { empProduct } from "../../assets";
import { Add } from "iconsax-react";
import { AddSubCategory } from "../alert/AddSubCategory";
import { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { AddMainCategory } from "../alert/AddMainCategory";
import { AddProducts } from "../alert/AddProducts";

export const EmptyMenu = (props) => {
  const {
    isCategory,
    catID,
    isLoading,
    brandID,
    onCategoriesChange,
    mainCatID,
    onAddFirstProduct,
    isParentHidden,
    showButton,
  } = props;
  const theme = useTheme();
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [openMainDialog, setOpenMainDialog] = useState(false);
  const [openProductDialog, setOpenProductDialog] = useState(false);

  const boxStyle = {
    bgcolor: theme.palette.menuHeader.main,
    borderRadius: 2,
    width: 250,
    height: "88vh",
  };
  const handleCloseSub = () => {
    setOpenSubDialog(false);
  };
  const handleOpenSub = () => {
    setOpenSubDialog(true);
  };

  const handleOpenMain = () => {
    setOpenMainDialog(true);
  };
  const handleCloseMain = () => {
    setOpenMainDialog(false);
  };

  const handleOpenNewProduct = () => {
    setOpenProductDialog(true);
  };
  const handleCloseNewProduct = () => {
    setOpenProductDialog(false);
  };

  return (
    <Grid container direction={"row"} sx={{ height: "100%" }} spacing={2}>
      <Grid item>
        <Stack
          spacing={2}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={boxStyle}
        >
          {!openMainDialog && !openProductDialog && isLoading ? (
            <CircularProgress sx={{ color: "blue.main" }} />
          ) : (
            <Fragment>
              <img src={empProduct} style={{ width: 180, height: 120 }} />
              <Typography
                sx={{
                  color: theme.palette.tertiary.main,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {isCategory ? "No Categories" : "No Subcategories/Products"}
              </Typography>
              {!isParentHidden && showButton && (
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<Add />}
                  color="theme"
                  onClick={() =>
                    isCategory ? handleOpenMain() : handleOpenSub()
                  }
                  style={{ textTransform: "none" }}
                  sx={{
                    width: 200,
                    height: 45,
                    ml: 2,
                  }}
                >
                  {isCategory ? "Add Category" : "Add Subcategory"}
                </Button>
              )}
              {openSubDialog && (
                <AddSubCategory
                  open={openSubDialog}
                  onClose={handleCloseSub}
                  catID={catID}
                  onSubmit={onCategoriesChange}
                />
              )}
              {openMainDialog && (
                <AddMainCategory
                  openMainDialog={openMainDialog}
                  onClose={handleCloseMain}
                  brandID={brandID}
                  onSubmit={onCategoriesChange}
                />
              )}
              {openProductDialog && (
                <AddProducts
                  openProductDialog={openProductDialog}
                  onClose={handleCloseNewProduct}
                  catID={catID}
                  mainCatID={mainCatID}
                  onSubmit={() => {
                    // onCategoriesChange();
                    onAddFirstProduct();
                  }}
                />
              )}
              {!isCategory && showButton && !isParentHidden && (
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  color="theme"
                  style={{ textTransform: "none" }}
                  sx={{
                    width: 200,
                    height: 45,
                    ml: 2,
                  }}
                  onClick={handleOpenNewProduct}
                >
                  Add Products
                </Button>
              )}
            </Fragment>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

EmptyMenu.propTypes = {
  isCategory: PropTypes.bool,
  catID: PropTypes.string,
  isLoading: PropTypes.bool,
  brandID: PropTypes.string,
  onCategoriesChange: PropTypes.func,
  mainCatID: PropTypes.string,
  onAddFirstProduct: PropTypes.func,
  isParentHidden: PropTypes.bool,
};

EmptyMenu.defaultProps = {
  isParentHidden: false,
};
