import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditQuantity from "../alert/EditQuantity";
import { Fragment, useState } from "react";
import SerialNumberView from "../alert/SerialNumberView";
import { PaginationTable } from "./PaginationTable";
import { useParams } from "react-router-dom";
import { currencyFormatter } from "../../utilities/extensions";
import { Img } from "react-image";

export const PurchaseOrderSummaryTable = (props) => {
  const {
    data,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    onQuantityChange,
  } = props;
  const { id } = useParams();
  const theme = useTheme();
  const [serialDialog, setSerialDialog] = useState(false);
  const [editQuantityDialog, setEditQuantityDialog] = useState(false);
  const [productID, setProductID] = useState("");
  const [productName, setProductName] = useState("");
  const [productQty, setProductQty] = useState(0);
  const [itemID, setItemID] = useState("");

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    height: 45,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  return (
    <Fragment>
      <Box
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: theme.palette.line.main,
        }}
      >
        <Stack direction={"column"} sx={{ pt: 2, pl: 2, pr: 2 }}>
          <Stack direction={"row"} alignItems={"center"} sx={{ gap: 2 }}>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              Order Summary
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                borderRadius: 6,
                bgcolor: theme.palette.altThemeThreeLight.main,
                pt: "6px",
                pl: "12px",
                pr: "12px",
                pb: "6px",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Total Quantity:
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 600,
                  ml: "4px",
                }}
              >
                {data?.total_quantity}
              </Typography>
            </Stack>
          </Stack>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerStyle}>No.</TableCell>
                  <TableCell sx={headerStyle}>Product</TableCell>
                  <TableCell sx={headerStyle}>Order ID</TableCell>
                  <TableCell sx={headerStyle}>Buyer</TableCell>
                  <TableCell sx={headerStyle}>Quantity</TableCell>
                  <TableCell sx={headerStyle}>Price</TableCell>
                  <TableCell sx={headerStyle} align="right">
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data?.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ width: 40, height: 45 }}>
                      <Typography sx={itemStyle}>
                        {itemsPerPage * (paginationData.page - 1) + index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ height: 45 }}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ gap: 1 }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {item.name}
                        </Typography>
                        {item.attributes?.map(
                          (attrItem) =>
                            attrItem.image !== null && (
                              <Img
                                src={attrItem.image}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  borderWidth: "0.5px",
                                  borderStyle: "solid",
                                  borderColor: theme.palette.tertiary.main,
                                }}
                              />
                            )
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ width: 70, height: 45 }}>
                      <Typography sx={itemStyle}>
                        {item.buyer_order_id}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: 70,
                        height: 45,
                      }}
                    >
                      <Typography sx={itemStyle}>{item.buyer_id}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: 140,
                        height: 45,
                      }}
                    >
                      <Stack direction={"row"}>
                        <Typography
                          sx={itemStyle}
                        >{`${item.scanned_product_count}/${item.quantity}`}</Typography>
                        {item.scanned_product_count > 0 && (
                          <Box>
                            <Tooltip
                              title={
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 500,
                                    fontSize: 12,
                                    cursor: "pointer",
                                  }}
                                >
                                  View Serial No.
                                </Typography>
                              }
                              color={theme.palette.primary.main}
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: theme.palette.lightTheme.main,
                                    "& .MuiTooltip-arrow": {
                                      color: theme.palette.lightTheme.main,
                                    },
                                  },
                                },
                              }}
                            >
                              <ArrowDropDownIcon
                                onClick={() => {
                                  setSerialDialog(true);
                                  setProductID(item.product_id);
                                  setProductName(item.name);
                                  setItemID(item.id);
                                }}
                                sx={{ ml: "2px", mr: "2px", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Box>
                        )}
                        {!data.seller_order_logs.find(
                          (x) =>
                            x.order_status.toLowerCase() === "package received"
                        ) && (
                          <Typography
                            sx={{
                              ml: "2px",
                              mr: "2px",
                              cursor: "pointer",
                              color: theme.palette.theme.main,
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                            onClick={() => {
                              setEditQuantityDialog(true);
                              setProductID(item.id);
                              setProductQty(item.quantity);
                            }}
                          >
                            Edit Qty
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: 120,
                        height: 45,
                      }}
                    >
                      <Typography sx={itemStyle}>
                        {currencyFormatter(item.unit_price, data.currency)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        width: 120,
                        height: 45,
                      }}
                    >
                      <Typography sx={itemStyle}>
                        {currencyFormatter(item.total_price, data.currency)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {serialDialog && (
              <SerialNumberView
                onClose={() => {
                  setSerialDialog(false);
                  setProductID("");
                  setProductName("");
                  setProductQty(0);
                  setItemID("");
                }}
                orderID={id}
                productID={productID}
                productName={productName}
                openserial={serialDialog}
                type={"purchase"}
                itemID={itemID}
              />
            )}
          </TableContainer>
          <PaginationTable
            page={paginationData.page - 1}
            onSetPage={(page) => setPageNumber(page + 1)} //page start with zero
            rowsPerPage={itemsPerPage}
            onSetRowsPerPage={setItemsPerPage}
            totalCount={paginationData.total_count}
          />
        </Stack>
      </Box>
      {editQuantityDialog && (
        <EditQuantity
          onClose={() => {
            setEditQuantityDialog(false);
            setProductID("");
            setProductName("");
            setProductQty(0);
          }}
          openEditQuantityDialog={editQuantityDialog}
          orderID={productID}
          oldQuantity={productQty}
          onSubmit={onQuantityChange}
        />
      )}
    </Fragment>
  );
};
