import { Grid, Stack } from "@mui/material";
import { SummaryHeader } from "../../components/headers/SummaryHeader";
import { PurchaseOrderSummaryTable } from "../../components/table/PurchaseOrderSummaryTable";
import { PurchaseSummaryStatus } from "./PurchaseSummaryStatus";
import { PurchasePaymentSummary } from "./PurchasePaymentSummary";
import { useParams } from "react-router";
import { useAuth, useToast } from "../../context";
import { Fragment, useEffect, useState } from "react";
import AddCharges from "../../components/alert/AddCharges";
import AddDiscount from "../../components/alert/AddDiscount";
import BuyerPurchaseScan from "../../components/alert/BuyerPurchaseScan";
import { useAPI } from "../../hooks/useAPI";
import { useDownloadAPI } from "../../hooks/useDownloadAPI";
import fileDownload from "js-file-download";
import { usePurchaseStore } from "../../store/store";
import { userRoles } from "../../utilities";

export const PurchaseSummary = () => {
  const { id } = useParams();
  const { logout, userRole } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const { downloadApi } = useDownloadAPI();
  const { setShouldReload } = usePurchaseStore((state) => state);

  const [reload, setReload] = useState(0);
  const [orderdetails, setOrderDetails] = useState({});
  const [scanDialog, setScanDialog] = useState(false);
  const [addChargeDialog, setAddChargeDialog] = useState(false);
  const [addDiscountDialog, setAddDiscountDialog] = useState(false);
  const [scanType, setScanType] = useState(1);
  const [status, setStatus] = useState("");
  const [currency, setCurrency] = useState("");

  const [requestData, setRequestData] = useState({
    itemsPerPage: 10,
    currentPage: 1,
  });
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  const getOrderDetails = () => {
    const { itemsPerPage, currentPage } = requestData;

    api
      .get(`${urls.purchaseOrderDetails}/${id}`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
        },
      })
      .then((res) => {
        setStatus("");
        setReload((prevCounter) => prevCounter + 1);
        setOrderDetails(res.data.data);
        setPaginationData({
          page: res.data.data.page,
          limit: res.data.data.limit,
          total_count: res.data.data.total_count,
        });
        setCurrency(res.data.data.currency);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  const updateStatus = () => {
    api
      .post(`${urls.updateSellerStatus}/${id}`, {
        status: status,
      })
      .then((res) => {
        showToast(res.data.message, "success");
        setShouldReload(true);
        getOrderDetails();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    getOrderDetails();
  }, [requestData]);

  useEffect(() => {
    if (status !== "") {
      updateStatus();
    }
  }, [status]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleCloseDialog = () => {
    getOrderDetails();
    setScanDialog(false);
  };

  const handleDeletePayment = (id) => {
    api
      .post(urls.purchaseRemoveChargeDiscount + id)
      .then((res) => {
        showToast(res.data.message, "success");
        getOrderDetails();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleDownloadPDF = (type) => {
    let params = {
      file_type: type,
    };
    downloadApi
      .get(urls.purchaseDetailsDownload + id, { params: params })
      .then((res) => {
        let data = res.data;
        fileDownload(data, `purchase-order-${orderdetails?.order_id}.${type}`);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.message, "error");
        }
      });
  };

  return (
    <Fragment>
      {addChargeDialog && (
        <AddCharges
          onClose={() => setAddChargeDialog(false)}
          openAddChargeDialog={addChargeDialog}
          onSubmit={() => {
            getOrderDetails();
            setAddChargeDialog(false);
          }}
          type="purchase"
          currency={currency}
        />
      )}
      {addDiscountDialog && (
        <AddDiscount
          onClose={() => setAddDiscountDialog(false)}
          openAddDiscountDialog={addDiscountDialog}
          onSubmit={() => {
            getOrderDetails();
            setAddDiscountDialog(false);
          }}
          type="purchase"
          currency={currency}
        />
      )}
      {scanDialog && (
        <BuyerPurchaseScan
          open={scanDialog}
          onClose={handleCloseDialog}
          type={scanType}
        />
      )}
      <Stack direction={"column"} sx={{ pr: 2, gap: 2 }}>
        <SummaryHeader
          title={`Order Details : # ${orderdetails?.order_id}`}
          scannedYet={orderdetails?.scanned_yet}
          onViewScan={(type) => {
            setScanType(type);
            setScanDialog(true);
          }}
          onDownloadClick={handleDownloadPDF}
          disableBtn={Boolean(
            orderdetails?.seller_order_logs?.find(
              (x) => x.order_status?.toLowerCase() === "inbound scan completed"
            )
          )}
          type={2}
          showScanOption={userRole !== userRoles.finance}
        />
        <Grid container direction={"row"} sx={{ gap: 2 }}>
          <Grid item xs={9}>
            <Stack direction={"column"} sx={{ gap: 2, pb: 2 }}>
              <PurchaseOrderSummaryTable
                data={orderdetails}
                paginationData={paginationData}
                itemsPerPage={requestData.itemsPerPage}
                setItemsPerPage={(count) =>
                  updateRequestData({ itemsPerPage: count, currentPage: 1 })
                }
                setPageNumber={(page) =>
                  updateRequestData({ currentPage: page })
                }
                onQuantityChange={getOrderDetails}
              />
              <PurchasePaymentSummary
                totalprice={orderdetails?.total_price}
                grandtotal={orderdetails?.grand_total}
                orderStatement={orderdetails?.seller_order_payment_logs}
                totalcommission={orderdetails?.total_commission}
                onAddChargeDialog={() => setAddChargeDialog(true)}
                onAddDiscountDialog={() => setAddDiscountDialog(true)}
                onDeletePayment={handleDeletePayment}
                currency={currency}
              />
            </Stack>
          </Grid>
          <Grid item xs>
            <PurchaseSummaryStatus
              reload={reload}
              data={orderdetails?.seller_order_logs}
              type={1}
              onSelected={(value) => {
                setStatus(value);
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Fragment>
  );
};
