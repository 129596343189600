import React, { Fragment, useEffect, useState } from "react";
import {
  Drawer,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Grid,
  useTheme,
  Avatar,
  useMediaQuery,
  Collapse,
  ListItemIcon,
  Fade,
  Typography,
  ListItem,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sideMenuItemsMain,
  sideMenuItemsWarehouse,
  sideMenuItemsFinance,
  sideMenuWithoutRole,
} from "../../utilities";
import { SideMenuIcon } from "../../icons/SideMenuIcon";
import { useAuth } from "../../context";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { logo } from "../../assets";
import { useLocalStorage } from "../../hooks";
import {
  usePurchaseStore,
  useSellStore,
  useRequestsStore,
  useUsersStore,
} from "../../store/store";
import { userRoles } from "../../utilities";

export const SideMenu = (props) => {
  const { isOpen, openMenu, closeMenu } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { logout, userData, userRole } = useAuth();
  const { getItem } = useLocalStorage();

  const [selected, setSelected] = useState(location.pathname);
  const [logoutDialog, setLogoutDialog] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { resetStore: resetPurchase } = usePurchaseStore((state) => state);
  const { resetStore: resetSell } = useSellStore((state) => state);
  const { resetStore: resetRequests } = useRequestsStore((state) => state);
  const { resetStore: resetUsers } = useUsersStore((state) => state);

  // useEffect(() => {
  //   if (selected == "/logout") {
  //     setLogoutDialog(true);
  //   } else if (
  //     selected === "/" ||
  //     selected === "/login" ||
  //     selected === "/otpverify"
  //   ) {
  //     setSelected("/dashboard");
  //   } else {
  //     navigate(selected);
  //     setSelected("");
  //   }
  // }, [selected]);

  const menuSelected = (m) => {
    //setSelected(m.link);
    if (m.link == "/logout") {
      setLogoutDialog(true);
    } else if (
      m.link === "/" ||
      m.link === "/login" ||
      m.link === "/otpverify"
    ) {
      navigate("/dashboard");
    } else {
      navigate(m.link);
      if (m.link !== location.pathname) {
        if (m.link === "/purchase") {
          resetPurchase();
        } else if (m.link === "/sell") {
          resetSell();
        } else if (m.link === "/requests") {
          resetRequests();
        } else if (m.link === "/users") {
          resetUsers();
        }
      }
    }
    if (!matches) {
      closeMenu();
    }
  };

  const listItemButtonStyle = {
    height: 45,
    borderRadius: 2,
    mb: 1.5,
    pl: 2.5,
    "&.Mui-selected": {
      backgroundColor: "#2C74FFE5",
      "&:hover": {
        backgroundColor: "#2C74FFE5",
      },
    },
    "&:hover": {
      backgroundColor: "#D0F2FF",
    },
  };

  function stringAvatar(name) {
    if (!name) return "- -"; // Return "NA" for an empty string
    const names = name.split(" ");
    if (names.length === 1) return `${names[0].charAt(0)}`; // Handle single word
    return `${names[0].charAt(0)}${names[1].charAt(0)}`; // Handle multiple words
  }

  const isSelected = (m) => {
    return (
      location.pathname.includes(m.link) &&
      location.pathname.indexOf(m.link) === 0
    );
  };

  return (
    <Fragment>
      <ReminderDialog
        open={logoutDialog}
        onClose={() => {
          setLogoutDialog(false);
          //setSelected(location.pathname);
        }}
        onConfirm={() => {
          logout();
          resetPurchase();
          resetSell();
          resetRequests();
          resetUsers();
        }}
        header={"Logout"}
        reminder={
          "You will be logged out from your account. Are you sure you want to log out?"
        }
        confirm={"Logout"}
      />
      <Drawer
        variant="permanent"
        open={isOpen}
        PaperProps={{
          sx: {
            backgroundColor: "#EDFAFF",
          },
        }}
      >
        {/* disable scrollbar */}
        <style>
          {`
          /* Hide scrollbar for Firefox */
          ::-webkit-scrollbar {
          display: none;
          }
          /* Hide scrollbar for IE/Edge */
          -ms-overflow-style: none;
        `}
        </style>
        <Collapse orientation="horizontal" in={isOpen} collapsedSize={100}>
          <List
            sx={{
              padding: 2,
              width: isOpen ? 250 : 65,
            }}
            onMouseEnter={openMenu}
            onMouseLeave={closeMenu}
          >
            <ListItem
              sx={{
                borderRadius: 2,
                bgcolor: theme.palette.lightTheme.main,
                "&:hover": {
                  backgroundColor: theme.palette.lightTheme.main,
                },
                height: 60,
              }}
            >
              <img
                src={logo}
                style={{
                  width: isOpen ? 45 : 35,
                  height: isOpen ? 45 : 35,
                }}
              />
              <Fade in={isOpen} timeout={{ enter: 1000 }}>
                <ListItemText
                  sx={{
                    ml: 1.5,
                  }}
                  primaryTypographyProps={{
                    color: "#040404",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {userData?.company_name}
                </ListItemText>
              </Fade>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                borderRadius: 2,
                height: 60,
                mt: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#FF4141",
                  width: 50,
                  height: 50,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {stringAvatar(userData?.owner_name)}
              </Avatar>
              {isOpen && (
                <Fade in={isOpen} timeout={{ enter: 1000 }}>
                  <Grid item sx={{ ml: 1.5, width: 188 }}>
                    <Typography
                      sx={{
                        color: "#040404",
                        fontSize: 20,
                        fontWeight: 500,
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {userData?.owner_name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#040404",
                        fontSize: 16,
                        fontWeight: 400,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {userData?.email}
                    </Typography>
                  </Grid>
                </Fade>
              )}
            </ListItem>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                ml: -2,
                mr: -2,
                height: "2px",
                bgcolor: theme.palette.common.white,
              }}
            />
            {/* Admin, master */}
            {(userRole === userRoles.superAdmin ||
              userRole === userRoles.master) &&
              sideMenuItemsMain[0].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
            {/* warehpose */}
            {userRole === userRoles.warehouse &&
              sideMenuItemsWarehouse[0].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
            {/* Finance */}
            {userRole === userRoles.finance &&
              sideMenuItemsFinance[0].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}

            {userRole === null &&
              sideMenuWithoutRole[0].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
            <Divider sx={{ mt: 2, mb: 2, ml: 3, mr: 3 }} />
            {/* admin. master */}
            {(userRole === userRoles.superAdmin ||
              userRole === userRoles.master) &&
              sideMenuItemsMain[1].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
            {/* warehouse */}
            {userRole === userRoles.warehouse &&
              sideMenuItemsWarehouse[1].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
            {/* finance */}
            {userRole === userRoles.finance &&
              sideMenuItemsFinance[1].map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={listItemButtonStyle}
                    selected={isSelected(m)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon id={m.id} selected={isSelected(m)} />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <ListItemText
                        sx={{
                          ml: 2,
                        }}
                        primaryTypographyProps={{
                          color: isSelected(m)
                            ? theme.palette.common.white
                            : theme.palette.common.primary,
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        {m.title}
                      </ListItemText>
                    </Fade>
                  </ListItemButton>
                );
              })}
          </List>
        </Collapse>
      </Drawer>
    </Fragment>
  );
};
