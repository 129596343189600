import { ArrowBack } from "@mui/icons-material";
import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { ArrowDown, ScanBarcode } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

export const SummaryHeader = (props) => {
  const {
    title,
    onViewScan,
    scannedYet,
    onDownloadClick,
    disableBtn,
    type,
    showScanOption,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadFile = (type) => {
    setAnchorEl(null);
    onDownloadClick(type);
  };

  return (
    <Stack direction={"column"} sx={{ gap: 2 }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <ArrowBack
            onClick={() => navigate(-1)}
            sx={{
              cursor: "pointer",
              borderRadius: "20px",
              padding: "5px",
              color: theme.palette.theme.main,
              boxShadow: "1px 1px 1px 1px lightblue",
            }}
          />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          {scannedYet && (
            <Button
              onClick={() => onViewScan(type)}
              variant="outlined"
              color="theme"
              sx={{ height: 45, textTransform: "none" }}
            >
              View Scanned Items
            </Button>
          )}
          <Button
            variant="contained"
            disableElevation
            color="lightTheme"
            style={{ textTransform: "none" }}
            startIcon={<ArrowDown size={20} />}
            sx={{
              width: 175,
              height: 45,
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={handleDownloadClick}
          >
            Download Order
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleDownloadClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleDownloadFile("pdf")}>
              <Stack direction={"row"} spacing={1} px={"5px"}>
                <PictureAsPdfOutlinedIcon sx={{ width: "20px" }} />
                <Typography>PDF</Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => handleDownloadFile("csv")}>
              <Stack direction={"row"} spacing={1} px={"5px"}>
                <TextSnippetOutlinedIcon sx={{ width: "20px" }} />
                <Typography>CSV</Typography>
              </Stack>
            </MenuItem>
          </Menu>
          {showScanOption && (
            <Button
              onClick={() => onViewScan(type)}
              variant="contained"
              disableElevation
              color="theme"
              style={{ textTransform: "none" }}
              startIcon={<ScanBarcode size={20} />}
              sx={{
                width: 175,
                height: 45,
                fontSize: 14,
                fontWeight: 500,
              }}
              disabled={disableBtn}
            >
              {scannedYet ? "Proceed Scan" : "Scan Items"}
            </Button>
          )}
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};

SummaryHeader.propTypes = {
  title: PropTypes.string,
  onViewScan: PropTypes.func,
  scannedYet: PropTypes.bool,
  onDownloadClick: PropTypes.func,
};
