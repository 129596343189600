import { Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { imageRoundSmallPlaceholder } from "../../assets";

export const ProductSettingsCard = (props) => {
  const theme = useTheme();
  const { name, imgitem, onEditClick, onDeleteClick, showButton } = props;

  return (
    <Stack direction={"column"} sx={{ height: 55 }}>
      <Divider />
      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ pl: 2, pr: 2, height: "100%" }}
      >
        <Grid item>
          <Stack direction={"row"} alignItems={"center"}>
            <img
              src={imgitem ?? "" !== "" ? imgitem : imageRoundSmallPlaceholder}
              width={34}
              height={34}
              style={{ borderRadius: "50%" }}
            />
            <Typography
              sx={{
                ml: 1,
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {name}
            </Typography>
          </Stack>
        </Grid>
        {showButton && (
          <Grid item>
            <Stack direction={"row"}>
              <Typography
                sx={{
                  color: theme.palette.theme.main,
                  fontSize: 16,
                  fontWeight: 500,
                  textTransform: "none",
                  cursor: "pointer",
                }}
                onClick={onEditClick}
              >
                Edit
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ ml: "12px", mr: "12px", mt: "4px", mb: "4px" }}
              />
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: 16,
                  fontWeight: 500,
                  textTransform: "none",
                  cursor: "pointer",
                }}
                onClick={onDeleteClick}
              >
                Remove
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
