import {
  Button,
  Divider,
  Grid,
  Stack,
  Breadcrumbs,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { TreeMenu } from "../../components/menu/TreeMenu";
import SearchField from "../../components/search/SearchField";
import { Add } from "iconsax-react";
import { StockTable } from "../../components/table/StockTable";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";
import { AttributesDropDown } from "../../components/dropdown/AttributesDropDown";
import { StocksDropDown } from "../../components/dropdown/StocksDropDown";
import { useNavigate } from "react-router-dom";
import { LoaderDialog } from "../../components/dialogs/LoaderDialog";
import { userRoles } from "../../utilities";

export const StockScreen = () => {
  const theme = useTheme();
  const { logout, userRole } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const navigate = useNavigate();
  const [productSelected, setProductSelected] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState(["Stocks"]);
  const [brands, setBrands] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [attributeList, setAttributeList] = useState([]);
  const [selectedRootCategory, setSelectedRootCategory] = useState(null);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [stocksLoading, setStocksLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    pageSize: 0,
    totalProducts: 0,
  });
  const [requestData, setRequestData] = useState({
    itemsPerPage: 10,
    currentPage: 1,
  });

  const [filterData, setFilterData] = useState({
    in_stock: "all",
    attributes: [],
  });
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState(null);
  const [currency, setCurrency] = useState("");
  const [resetDate, setResetDate] = useState(Date());

  const handleCategorySelect = (nodeId) => {
    const getAllCategoryNames = (id) => {
      const searchItem = (data, id) => {
        for (const item of data) {
          if (item.id === id) {
            return [item.name];
          }
          if (item.categories) {
            const foundInChild = searchItem(item.categories, id);
            if (foundInChild) {
              return [item.name, ...foundInChild];
            }
          }
        }
        return null;
      };

      return searchItem(brands, id);
    };

    const findItemById = (id) => {
      const searchItem = (data, id) => {
        for (const item of data) {
          if (item.id === id) {
            return item;
          }
          if (item.categories) {
            const foundInChild = searchItem(item.categories, id);
            if (foundInChild) {
              return foundInChild;
            }
          }
        }
        return null;
      };

      return searchItem(brands, id);
    };

    const selectedItem = findItemById(nodeId);
    console.log("🚀 ~ handleCategorySelect ~ selectedItem:", selectedItem);
    const categoryNames = getAllCategoryNames(nodeId);
    setBreadCrumbs(["Stocks", ...categoryNames]);
    if (selectedItem.main_category !== selectedRootCategory) {
      console.log("🚀 ~ CHANGINgg.......");
      setAttributeList(selectedItem.attribute_types);
      setSelectedRootCategory(selectedItem.main_category);
      setFilterData({ in_stock: "all", attributes: [] });
      setResetDate(Date());
    }

    setProductSelected(nodeId);
    setRequestData({
      itemsPerPage: 10,
      currentPage: 1,
    });
    setSearchText("");
  };

  const handleOpenNewProduct = () => {
    navigate("/products");
  };

  const onSearch = (text) => {
    setSearchText(text);
  };

  const onSelectedAttribute = (index, value) => {
    setFilterData((prev) => {
      const updatedAttributes = [...prev.attributes]; // Create a copy of attributes array
      updatedAttributes[index] = {
        ...updatedAttributes[index], // Preserve existing properties of the object
        [index]: value, // Update the value associated with the specified index
      };
      return { ...prev, attributes: updatedAttributes }; // Return updated state
    });
  };

  const onSelectedStock = (stock) => {
    setFilterData((prev) => ({
      ...prev,
      in_stock: stock === 1 ? "all" : stock === 2 ? "yes" : "no",
    }));
  };

  const getBrands = () => {
    setBrandsLoading(true);
    api
      .get(urls.stockBrandsList)
      .then((res) => {
        let brandsList = res.data.data;

        // propagate attribute_types recursively
        const propagateAttributes = (category) => {
          // If the category has subcategories
          if (category.categories && category.categories.length > 0) {
            // Iterate over each subcategory
            category.categories.forEach((subCategory) => {
              // Set the attribute_types from the parent category
              subCategory.attribute_types = category.attribute_types;
              subCategory.main_category = category.main_category
                ? category.main_category
                : category.id;

              // Recursively propagate attributes to deeper subcategories
              propagateAttributes(subCategory);
            });
          }
        };

        // Iterate through each brand and category to propagate attributes
        brandsList.forEach((brand) => {
          brand.categories.forEach((category) => {
            category.main_category = category.id;
            propagateAttributes(category);
          });
        });

        // Set the updated brand list
        setBrands(brandsList);
        setBrandsLoading(false);
      })
      .catch((err) => {
        setBrandsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getStocks = () => {
    setStocksLoading(true);
    const filter = { in_stock: filterData.in_stock };
    if (filterData.attributes.length > 0) {
      const ids = filterData.attributes
        .map((item) => {
          for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
              const value = item[key];
              if (value !== null && value !== undefined) {
                return value; // Return value and assign to the array ids if it's not null or undefined
              }
            }
          }
        })
        .filter((value) => value !== undefined);
      filter.attributes = ids;
    }
    const params = {
      category_id: productSelected,
      page: requestData.currentPage,
      limit: requestData.itemsPerPage,
      filter: filter,
      search_keyword: searchText,
    };
    api
      .post(urls.stocksList, params)
      .then((res) => {
        let data = res.data.data;
        setStocks(data.products);
        setPaginationData(data.pagination);
        setStocksLoading(false);
        setCurrency(data.currency ?? "");
      })
      .catch((err) => {
        setStocksLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    // if (productSelected !== null) {
    getStocks();
    // }
  }, [requestData, filterData]);

  useEffect(() => {
    // if (searchText !== "") {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      getStocks();
    }, 500);

    setTimer(newTimer);
    // }
  }, [searchText]);

  if (brandsLoading) {
    return <LoaderCard />;
  }

  return (
    <Fragment>
      <LoaderDialog open={isLoading} />
      <Grid
        container
        direction={"row"}
        sx={{ width: "100%", height: "100vh" }}
        spacing={2}
      >
        <Grid
          item
          sx={{
            pr: 2,
            bgcolor: theme.palette.menu.main,
          }}
        >
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Stocks
          </Typography>
          <TreeMenu
            onCategorySelect={handleCategorySelect}
            productSelected={productSelected}
            brands={brands}
          />
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mt: -2, mb: -2 }} />
        <Grid
          item
          direction={"column"}
          xs
          sx={{ ml: 2, mr: 2, display: "flex" }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.tertiary.main,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <Breadcrumbs aria-label="breadcrumb">
                  {breadCrumbs.map((text, index) => {
                    return (
                      <Typography color="text.primary" key={index}>
                        {text}
                      </Typography>
                    );
                  })}
                </Breadcrumbs>
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction={"row"}>
                <SearchField key={resetDate} onSearchTextChange={onSearch} />
                {userRole === userRoles.superAdmin && (
                  <Button
                    variant="contained"
                    disableElevation
                    startIcon={<Add />}
                    color="theme"
                    style={{ textTransform: "none" }}
                    sx={{
                      width: 175,
                      height: 45,
                      ml: 2,
                    }}
                    onClick={handleOpenNewProduct}
                  >
                    Add Product
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Stack sx={{ mt: 3 }} direction={"row"} alignItems={"center"}>
            <Stack
              direction={"row"}
              sx={{
                height: 45,
                bgcolor: theme.palette.menuAlt.main,
                borderRadius: "8px",
              }}
              key={resetDate}
            >
              <StocksDropDown onSelectedStock={onSelectedStock} />
              {attributeList?.map((item, index) => (
                <Fragment key={index}>
                  <Divider orientation="vertical" />
                  <AttributesDropDown
                    key={item.attribute_id}
                    attribute={item}
                    selectedAttribute={filterData.attributes}
                    onSelectedAttribute={(key, value) =>
                      onSelectedAttribute(index, value)
                    }
                  />
                  {index !== attributeList.length - 1 && (
                    <Divider orientation="vertical" />
                  )}
                </Fragment>
              ))}
            </Stack>
            {(filterData.in_stock !== "all" ||
              filterData.attributes.length > 0) && (
              <Typography
                sx={{
                  ml: 1,
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFilterData({ in_stock: "all", attributes: [] });
                  setResetDate(Date());
                }}
              >
                Clear Filter
              </Typography>
            )}
          </Stack>
          <Grid item sx={{ mt: 2 }}>
            {stocksLoading && stocks.length === 0 && <LoaderCard />}
            {!stocksLoading && stocks.length === 0 && (
              <EmptyCard text={"No products available"} height={"50dvh"} />
            )}
            {stocks.length > 0 && (
              <StockTable
                data={stocks}
                paginationData={paginationData}
                requestData={requestData}
                setRequestData={setRequestData}
                currency={currency}
              />
            )}
          </Grid>
        </Grid>
        {/*  )} */}
      </Grid>
    </Fragment>
  );
};
